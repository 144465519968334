<template>
  <div>
    <topBarY />
    <div class="bg">
      <img src="../../assets/common/pic/bgss.jpg" alt="">
    </div>
    <div class="switchBar">
      <div class="tab video" :class="{ active: switchIndex == 1 }" @click="switchClick(1)">视频传输应用</div>
      <div class="tab data" :class="{ active: switchIndex == 2 }" @click="switchClick(2)">数据采集应用</div>
      <div class="tab robot" :class="{ active: switchIndex == 3 }" @click="switchClick(3)">移动机器无线覆盖传输</div>
      <div class="tab cabinet" :class="{ active: switchIndex == 3 }" @click="switchClick(4)">智能无线保管柜</div>
    </div>
    <div class="line"></div>
    <div class="switchContiner">
      <div class="continer videoContiner" v-if="switchIndex == 1">
        <div to="video" class="plan" @click="monitoringClick">
          <img src="../../assets/solution/monitoring.jpg" alt="">
          <span>无线视频传输应用（视距、固定点）</span>
        </div>
        <div to="video" class="plan last" @click="cityClick">
          <img src="../../assets/solution/city.jpg" alt="">
          <span>移动MESH无线视频覆盖传输（非视距、移动点）</span>
        </div>
      </div>
      <div class="continer dataContiner" v-if="switchIndex == 2">
        <div class="plan" @click="factoryClick">
          <img src="../../assets/solution/factory.jpg" alt="">
          <span>工业无线数传</span>
        </div>
        <div class="plan last" @click="warehouseClick">
          <img src="../../assets/solution/warehouse.jpg" alt="">
          <span>物流无线覆盖传输</span>
        </div>
      </div>
      <div class="continer robotContiner" v-if="switchIndex == 3">
        <div class="plan" @click="robotClick">
          <img src="../../assets/solution/robot.jpg" alt="">
          <span>AGV移动机器人覆盖漫游传输</span>
        </div>
        <div class="plan" @click="electricityClick">
          <img src="../../assets/solution/electricity.jpg" alt="">
          <span>电力巡检覆盖传输</span>
        </div>
        <div class="plan last" @click="pipeClick">
          <img src="../../assets/solution/pipe.jpg" alt="">
          <span>管廊覆盖及定位</span>
        </div>
      </div>
      <div class="continer robotContiner" v-if="switchIndex == 4">
        <div class="plan" @click="cabinetClick">
          <img src="../../assets/solution/cabinet.png" alt="">
          <span>智能无线保管柜</span>
        </div>
      </div>
    </div>
    <baseBar />
  </div>
</template>
  
<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
  name: 'Solution',
  components: {
    topBarY,
    baseBar
  },
  data() {
    return {
      switchIndex: 3
    }
  },
  methods: {
    switchClick(index) {
      this.switchIndex = index
    },
    monitoringClick() {
      this.$router.push('video')
    },
    cityClick() {
      this.$router.push('city')
    },
    factoryClick() {
      this.$router.push('factory')
    },
    warehouseClick() {
      this.$router.push('warehouse')
    },
    robotClick() {
      this.$router.push('robot')
    },
    electricityClick() {
      this.$router.push('electricity')
    },
    pipeClick() {
      this.$router.push('pipe')
    },
    cabinetClick() {
      this.$router.push('cabinet')
    }
  },
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  }
}
</script>
  
<style scoped>
.bg {
  position: relative;
  width: 1920px;
  height: 480px;
  overflow: hidden;
}

.bg img {
  position: absolute;
  width: 1920px;
}

.switchBar {
  height: 70px;
  background-color: #fff;
  text-align: center;
}

.switchBar .tab {
  display: inline-block;
  margin-right: 30px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 5px;
  width: 200px;
  height: 40px;
  background-color: #c3a35d;
  color: #f5f5f5;
  font-family: 'Microsoft Yahei';
  font-size: 16px;
  line-height: 40px;
}

.switchBar .video {
  width: 140px;
}

.switchBar .data {
  width: 140px;
}

.switchBar .cabinet {
  width: 160px;
  margin-right: 0;
}

.line {
  margin: 0 auto;
  width: 1400px;
  height: 1px;
  background-color: #c3a35d;
}

.switchContiner {
  width: 1920px;
  padding: 20px;
  background-color: #f7f7f7;
  text-align: center;
}

.switchContiner .continer {
  cursor: pointer;
}

.switchContiner .continer .plan {
  position: relative;
  display: inline-block;
  width: 400px;
  height: 220px;
  background-color: #f7f7f7;
  margin-top: 40px;
  margin-right: 40px;
  text-align: left;
  font-size: 16px;
}

.switchContiner .continer .last {
  margin-right: 0;
}

.switchContiner .continer .plan:hover {
  box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
  transform: translate3d(0, -2px, 0);
  transition: all 0.2s linear;
}

.switchContiner .continer .plan span {
  position: absolute;
  left: 10px;
  bottom: 5px;
  font-weight: 700;
}

.switchContiner .continer .plan img {
  width: 400px;
  height: 220px;
}

/* 视频传输应用 */
.switchContiner .videoContiner {
  margin: 0 auto;
  width: 1400px;
  height: 300px;
  border-radius: 5px;
  background-color: #fff;
}

/* 数据采集应用 */
.switchContiner .dataContiner {
  margin: 0 auto;
  width: 1400px;
  height: 300px;
  border-radius: 5px;
  background-color: #fff;
}

/* 移动机器无线覆盖传输 */
.switchContiner .robotContiner {
  margin: 0 auto;
  width: 1400px;
  height: 300px;
  border-radius: 5px;
  background-color: #fff;
}
</style>
  